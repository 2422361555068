import {useAppDispatch, useAppSelector} from '@/store'
import {selectDarkMode, setIsDark} from '@/store/slices/commonSlice'
import {Icon} from 'pepsico-ds'
import React, {useEffect} from 'react'

interface ThemeProps {
  className?: string
}

const ThemeToggle: React.FC<ThemeProps> = ({className = ''}) => {
  const dispatch = useAppDispatch()
  const isDark = useAppSelector(selectDarkMode)

  useEffect(() => {
    if (isDark) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
  }, [isDark])

  return (
    <Icon
      onClick={() => dispatch(setIsDark(!isDark))}
      icon={isDark ? 'light_mode' : 'dark_mode'}
      className={`${className} cursor-pointer`}
    />
  )
}

export default ThemeToggle

import {Drawer} from '@/components/Drawer/Drawer'
import EventNotificationDetails from '@/components/Drawer/EventNotificationDetails'
import {getNotificationTableColumns} from '@/components/EventListTable/components/NotificationTableColumn'
import {getNotificationsUseCase} from '@/useCases/getNotificationsUseCase'
import {useQuery} from '@tanstack/react-query'
import {PaginationState, SortingState} from '@tanstack/react-table'
import {useCallback, useState} from 'react'
import {useIntl} from 'react-intl'
import {DataTable} from '../../shared-components/DataTable'

const initialRequestParams = {
  idCountry: 1,
  pageNumber: 0,
  page: 0,
  pageSize: 10,
  sortBy: 'name,asc',
}

type requestParams = {
  idCountry: number
  pageNumber: number
  page: number
  pageSize: number
  sortBy: string
}

export default function EventListTable() {
  const {formatMessage} = useIntl()
  const [sorting, setSorting] = useState<SortingState>([])
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState<boolean>(false)
  const [notificationId, setSelectedNotificationId] = useState<string>('')
  const [requestParams, setRequestParams] = useState<requestParams>(initialRequestParams)

  const {isFetching, data, error} = useQuery({
    queryKey: ['notifications', requestParams],
    queryFn: ({signal}) => getNotificationsUseCase(requestParams, signal),
  })

  const onPaginationDidChange = useCallback(
    ({pageIndex, pageSize}: PaginationState) => {
      setRequestParams({...requestParams, pageNumber: pageIndex, pageSize})
    },
    [setRequestParams, requestParams]
  )

  const onSortingDidChange = useCallback(
    (sorting: SortingState) => {
      const column = sorting[0]
      const sortIndicator = column?.desc ? 'DESC' : 'ASC'
      const newSort = !column ? '' : `${column.id},${sortIndicator}`
      setRequestParams({...requestParams, sortBy: newSort})
      setSorting(sorting)
    },
    [setSorting, setRequestParams, requestParams]
  )

  return (
    <div data-testid="event-list-table-container">
      {isFetching && (
        <p className="text-md my-16 items-center text-center" data-testid="notification-loading">
          {formatMessage({id: 'NOTIFICATION_LIST.LOADING_TABLE_LABEL'})}
        </p>
      )}
      {!isFetching && error && (
        <p className="text-md my-16 items-center text-center" data-testid="notification-error">
          {formatMessage({id: 'NOTIFICATION_LIST.ERROR_TABLE_LABEL'})}
        </p>
      )}
      {!isFetching && !error && (
        <DataTable
          columns={getNotificationTableColumns(formatMessage, setIsEventDetailsOpen, setSelectedNotificationId)}
          data={data?.data}
          pagination={{pageIndex: requestParams.pageNumber, pageSize: requestParams.pageSize}}
          pageCount={data?.pagination?.totalPages ?? 1}
          onPaginationDidChange={onPaginationDidChange}
          sortingColumn={sorting}
          onSortingDidChange={onSortingDidChange}
          emptyMessage={formatMessage({id: 'NOTIFICATION_LIST.NO_NOTIFICATIONS_FOUND'})}
          data-testid="notification-content"
        />
      )}
      <Drawer
        headerTitle="Test Header"
        isOpen={isEventDetailsOpen}
        onClose={() => {
          setIsEventDetailsOpen(false)
        }}
        direction="right"
      >
        <EventNotificationDetails notificationId={notificationId} />
      </Drawer>
    </div>
  )
}

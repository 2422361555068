import {z} from 'zod'

export const GetNotificationsRequestSchema = z.object({
  idCountry: z.number().optional(),
  pageNumber: z.number().optional(),
  pageSize: z.number().optional(),
  sortBy: z.string().optional(),
})

const NotificationListItemSchema = z.object({
  id: z.string(),
  name: z.string().max(100),
  stepType: z.string().optional(),
  eventName: z.string().optional(),
  eventStatus: z.string().optional(),
  publishedOn: z.string().nullable().optional(),
})

const NotificationPaginationSchema = z.object({
  pageNumber: z.number().optional(),
  pageSize: z.number().optional(),
  totalElements: z.number().optional(),
  totalPages: z.number().optional(),
})

const TraitParameterSchema = z.object({
  id_traitparameter: z.number(),
  id_operator: z.number(),
  id_operatorvalue: z.number(),
  value: z.string(),
})

const EntryConditionSchema = z.object({
  id_trait: z.number(),
  id_operator: z.number(),
  id_traitvalue: z.number(),
  value: z.string().optional(),
  is_primary: z.boolean().optional(),
  traitparameters: z.array(TraitParameterSchema).optional(),
})

const MessageConfigSchema = z.object({
  id_language: z.number(),
  name_language: z.string(),
  subject: z.string(),
  message: z.string(),
})

const StepSchema = z.object({
  id_step: z.number(),
  id_steptype: z.number(),
  id_stepstatus: z.number(),
  message_configs: z.array(MessageConfigSchema),
})

export const CreateNotificationRequestSchema = z.object({
  id_country: z.number(),
  id_eventstatus: z.number(),
  name: z.string(),
  description: z.string(),
  active: z.boolean(),
  redirect_page: z.string().url().optional(),
  entry_conditions: z.array(EntryConditionSchema),
  steps: z.array(StepSchema),
})

export const CreateNotificationResponseSchema = z.object({
  success: z.boolean(),
  data: z.unknown(),
})

export type NotificationListItem = z.infer<typeof NotificationListItemSchema>

export const GetNotificationsResponseSchema = z.object({
  data: z.array(NotificationListItemSchema),
  pagination: NotificationPaginationSchema,
  success: z.boolean().optional(),
  errorMessage: z.string().nullable().optional(),
})

const notificationTypeSchema = z.object({
  stepType: z.object({
    name: z.string(),
    description: z.string().optional(),
  }),
})

const traitSchema = z.object({
  name: z.string(),
  description: z.string().nullable().optional(),
  id_trait: z.number(),
})

const operatorSchema = z.object({
  name: z.string(),
  description: z.string().nullable().optional(),
  id_operator: z.number(),
})

const parameterSchema = z.object({
  id_traitparameter: z.number(),
  trait_name: z.string(),
  id_operator: z.number().optional(),
  operator_name: z.string(),
  id_operatorvalue: z.number().optional(),
  value: z.string(),
})

const notificationDetailsSchema = z.object({
  id: z.string(),
  name: z.string().max(100),
  code: z.string().nullable().optional(),
  description: z.string().optional(),
  steps: z.array(notificationTypeSchema),
  eventStatus: z.object({name: z.string(), description: z.string().optional()}),
  entry_conditions: z.array(
    z.object({
      trait: traitSchema,
      operator: operatorSchema,
      value: z.string(),
      traitparameters: z.array(parameterSchema),
    })
  ),
  country_name: z.string(),
  redirect_page: z.string(),
})

export const GetNotificationByIdRequestSchema = z.void()
export const GetNotificationByIdResponseSchema = z.object({
  data: notificationDetailsSchema,
  success: z.boolean().optional(),
  errorMessage: z.string().nullable().optional(),
})

export type GetNotificationByIdRequest = z.infer<typeof GetNotificationByIdRequestSchema>
export type GetNotificationByIdResponse = z.infer<typeof GetNotificationByIdResponseSchema>
export type GetNotificationsRequest = z.infer<typeof GetNotificationsRequestSchema>
export type GetNotificationsResponse = z.infer<typeof GetNotificationsResponseSchema>
export type CreateNotificationRequest = z.infer<typeof CreateNotificationRequestSchema>
export type CreateNotificationResponse = z.infer<typeof CreateNotificationResponseSchema>
export type TraitParameter = z.infer<typeof TraitParameterSchema>
export type EntryCondition = z.infer<typeof EntryConditionSchema>
export type MessageConfig = z.infer<typeof MessageConfigSchema>
export type Step = z.infer<typeof StepSchema>

import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {RemoveParameterButton} from '@/shared-components/RemoveParameterButton'
import {useIntl} from 'react-intl'

type NotificationParamSelectorProps = {
  id: number
  deleteParam: () => void
}

export default function NotificationParamSelector({id, deleteParam}: NotificationParamSelectorProps) {
  const {formatMessage} = useIntl()

  return (
    <div data-testid="notification-param-selector-container">
      <div className="mb-4 flex gap-4 text-sm font-bold leading-6">
        {formatMessage({id: 'ENTRY_CONDITION_CONTENT.PARAMETER'}, {position: id})}
        <RemoveParameterButton iconSize="small" onClick={deleteParam} />
      </div>
      <div className="relative flex w-full flex-wrap items-start justify-between gap-2">
        <div className="flex w-full max-w-[300px] gap-2">
          <InputSelector
            id="param"
            label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.PARAMETER_CONDITION_TRAIT'})}
            required
          >
            <option hidden>{formatMessage({id: 'ENTRY_CONDITION_CONTENT.SELECT_A_PARAMETER'})}</option>
          </InputSelector>
        </div>
        <div className="flex w-full max-w-[300px] gap-2">
          <InputSelector
            id="operator"
            label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.PARAMETER_CONDITION_OPERATOR'})}
            required
          >
            <option hidden>{formatMessage({id: 'ENTRY_CONDITION_CONTENT.SELECT_OPERATOR'})}</option>
          </InputSelector>
        </div>
        <div className="flex w-full max-w-[300px] gap-2">
          <InputSelector
            id="value"
            label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.PARAMETER_CONDITION_VALUE'})}
            required
          >
            <option hidden>{formatMessage({id: 'ENTRY_CONDITION_CONTENT.SELECT_VALUE'})}</option>
          </InputSelector>
        </div>
      </div>
    </div>
  )
}

import {JourneyType, JourneyTypeSchema} from '@/types/JourneyType'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {ROUTES} from '../../../config/constants'

export const JourneyNameCell = ({name, id}: {name: string; id: string}) => {
  return (
    <div data-testid="name-cell" data-cy="name-cell">
      <Link
        to={ROUTES.EDIT_JOURNEY.replace(':id', id)}
        state={{prevPath: window.location.pathname + window.location.search}}
        className="font-inter text-sm text-link no-underline hover:text-link-hover"
        data-cy={`journey-item-${id}`}
        title="Edit Journey"
      >
        {name}
      </Link>
    </div>
  )
}

export const JourneyDescriptionCell = ({description}: {description: string}) => {
  return (
    <div data-testid="description-cell" className="journey-table-style">
      {description}
    </div>
  )
}

export const JourneyTypeCell = ({type = JourneyTypeSchema.enum.RECURRENT}: {type?: JourneyType}) => {
  const {formatMessage} = useIntl()

  return (
    <div data-testid="type-cell" className="journey-table-style">
      {formatMessage({id: `NBA_BUILDER.NBA_TYPE_${type}`})}
    </div>
  )
}

export const DatetimeCell = ({value, locale = []}: {value?: string; locale?: Intl.LocalesArgument}) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }

  const strDate = value ? `${new Date(value).toLocaleString(locale, options)}` : '-'
  const formatted = strDate.replace(',', '')
  return (
    <div data-testid="datetime-cell" className="text-sm">
      {formatted}
    </div>
  )
}

import {ROUTES} from '@/config/constants'
import {SecondaryButton} from '@/shared-components/Button'
import {useAuth} from '@/hooks/useAuth'
import {useIntl} from 'react-intl'

export const Logout = () => {
  const {instance} = useAuth()
  const {formatMessage} = useIntl()

  const onClickLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: ROUTES.HOME,
    })
  }

  return (
    <div className="flex justify-end">
      <SecondaryButton data-testid="btn-logout" onClick={onClickLogout}>
        {formatMessage({id: 'GENERAL.LOGOUT'})}
      </SecondaryButton>
    </div>
  )
}

import {HowtoExecutionStatus} from '@/pages/Template/components/UserGuideContent/HowtoExecutionStatus'
import {HowtoSteps} from '@/pages/Template/components/UserGuideContent/HowtoSteps'
import {Accordion, AccordionItem} from 'pepsico-ds'
import {PropsWithChildren, useState} from 'react'
import {useIntl} from 'react-intl'
import {HowtoAudience} from './HowtoAudience'
import {HowtoCreate} from './HowtoCreate'
import {HowtoDelete} from './HowtoDelete'
import {HowtoMessageThresholdLimits} from './HowtoMessageThresholdLimits'
import {HowtoPublish} from './HowtoPublish'
import {HowtoSave} from './HowtoSave'

export const UserGuideContent = () => {
  const {formatMessage} = useIntl()
  const [accordionContextIndex, setAccordionContextIndex] = useState([0, -1])

  return (
    <div className="flex h-full w-full gap-8 text-sm" data-cy="user-guide-content">
      <div className="user-guide h-full w-full overflow-y-auto text-text-primary">
        <UserGuideContext>{formatMessage({id: 'USER_GUIDE.CONTEXT_EDITOR_TITLE'})}</UserGuideContext>
        <Accordion
          size="large"
          selectedItemIndex={accordionContextIndex[0]}
          onUpdate={newIndex => setAccordionContextIndex([newIndex, -1])}
        >
          <AccordionItem label={formatMessage({id: 'USER_GUIDE.SECTION_CREATE_TITLE'})}>
            <HowtoCreate />
          </AccordionItem>

          <AccordionItem label={formatMessage({id: 'USER_GUIDE.SECTION_AUDIENCE_CRITERIA_TITLE'})}>
            <HowtoAudience />
          </AccordionItem>

          <AccordionItem label={formatMessage({id: 'USER_GUIDE.SECTION_STEPS_TITLE'})}>
            <HowtoSteps />
          </AccordionItem>

          <AccordionItem label={formatMessage({id: 'USER_GUIDE.SECTION_SAVE_TITLE'})}>
            <HowtoSave />
          </AccordionItem>

          <AccordionItem label={formatMessage({id: 'USER_GUIDE.SECTION_PUBLISH_TITLE'})}>
            <HowtoPublish />
          </AccordionItem>

          <AccordionItem label={formatMessage({id: 'USER_GUIDE.SECTION_DELETE_TITLE'})}>
            <HowtoDelete />
          </AccordionItem>

          <AccordionItem label={formatMessage({id: 'USER_GUIDE.SECTION_EXECUTION_STATUS_TITLE'})}>
            <HowtoExecutionStatus />
          </AccordionItem>
        </Accordion>

        <UserGuideContext data-testid="user-guide-settings">
          {formatMessage({id: 'USER_GUIDE.CONTEXT_SETTINGS_TITLE'})}
        </UserGuideContext>
        <Accordion
          size="large"
          selectedItemIndex={accordionContextIndex[1]}
          onUpdate={newIndex => setAccordionContextIndex([-1, newIndex])}
        >
          <AccordionItem label={formatMessage({id: 'USER_GUIDE.SECTION_MESSAGES_THRESHOLD_TITLE'})}>
            <HowtoMessageThresholdLimits />
          </AccordionItem>

          <>{/* It's required to have at least two children in the Accordion, otherwise it crashes! */}</>
        </Accordion>
      </div>
    </div>
  )
}

const UserGuideContext = (props: PropsWithChildren) => {
  return (
    <h2 className="my-4 border-l-4 border-link bg-layer-02 py-2 pl-3 text-xl font-semibold text-link" {...props}>
      {props.children}
    </h2>
  )
}

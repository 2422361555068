import {CreateNotificationRequest, CreateNotificationRequestSchema} from '@/types/Notifications'

export const createNotificationData = (
  eventStatus: number,
  notificationName: string,
  notificationDescription: string,
  subject: string,
  message: string
): CreateNotificationRequest => {
  const notificationData = {
    id_country: 1,
    id_eventstatus: eventStatus,
    name: notificationName,
    description: notificationDescription,
    active: true,
    entry_conditions: [
      {
        id_trait: 1,
        id_operator: 1,
        id_traitvalue: 1,
      },
    ],
    steps: [
      {
        id_step: 1,
        id_steptype: 1,
        id_stepstatus: 1,
        message_configs: [
          {
            id_language: 1,
            name_language: 'English',
            subject: subject,
            message: message,
          },
        ],
      },
    ],
  }

  const validatedData = CreateNotificationRequestSchema.parse(notificationData)

  return validatedData
}

import {ENDPOINTS} from '@/config/constants'
import {pathBuilder} from '@/helpers/pathBuilder'
import api, {HTTPMethod} from '../helpers/api'
import {
  CreateNotificationRequest,
  CreateNotificationRequestSchema,
  CreateNotificationResponse,
  CreateNotificationResponseSchema,
  GetNotificationByIdRequest,
  GetNotificationByIdRequestSchema,
  GetNotificationByIdResponse,
  GetNotificationByIdResponseSchema,
  GetNotificationsRequest,
  GetNotificationsRequestSchema,
  GetNotificationsResponse,
  GetNotificationsResponseSchema,
} from '../types/Notifications'

export const getNotificationsService = (params: GetNotificationsRequest, signal?: AbortSignal) => {
  return api<GetNotificationsRequest, GetNotificationsResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.LOAD_EVENTS,
    requestSchema: GetNotificationsRequestSchema,
    responseSchema: GetNotificationsResponseSchema,
    signal,
  })(params)
}

export const createNotificationService = (params: CreateNotificationRequest, signal?: AbortSignal) => {
  return api<CreateNotificationRequest, CreateNotificationResponse>({
    method: HTTPMethod.POST,
    path: ENDPOINTS.CREATE_EVENT,
    requestSchema: CreateNotificationRequestSchema,
    responseSchema: CreateNotificationResponseSchema,
    signal,
  })(params)
}
export const getNotificationByIdService = (id: string, signal?: AbortSignal) => {
  return api<GetNotificationByIdRequest, GetNotificationByIdResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.LOAD_EVENT).withId(id).build(),
    requestSchema: GetNotificationByIdRequestSchema,
    responseSchema: GetNotificationByIdResponseSchema,
    signal,
  })()
}

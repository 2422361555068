import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '@/hooks/useAuth'
import {ROUTES} from '@/config/constants'

export const Login = () => {
  const {handleLogin, isAuthenticated} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate(ROUTES.HOME)
    }
  }, [isAuthenticated, navigate])

  return (
    <div>
      {isAuthenticated ? (
        <p>Redirecting to home...</p>
      ) : (
        <>
          <p>You are not logged in. Please log in to continue.</p>
          <button onClick={() => handleLogin('popup')}>Login</button>
        </>
      )}
    </div>
  )
}

import {AddNewParameterButton} from '@/shared-components/AddNewParameterButton'
import {useState} from 'react'
import NotificationParamSelector from './NotificationParamSelector'

const NotificationParams: React.FC = () => {
  const [paramSelectors, setParamSelectors] = useState<number[]>([])

  const addParamSelector = () => {
    setParamSelectors(params => [...params, params.length + 1])
  }

  const deleteParamSelector = (paramNumber: number) => {
    setParamSelectors(params => params.filter(param => param !== paramNumber))
  }

  return (
    <div>
      {paramSelectors.length > 0 && (
        <div>
          {paramSelectors.map(param => (
            <div
              key={param}
              className="mb-4 w-full max-w-[678px] rounded-lg border-2 border-dashed border-blue bg-layer-02 p-4 transition-all duration-300 hover:border-layer-03"
            >
              <NotificationParamSelector id={param} deleteParam={() => deleteParamSelector(param)} />
            </div>
          ))}
        </div>
      )}
      <AddNewParameterButton className="max-w-xs" onClick={addParamSelector} />
    </div>
  )
}

export default NotificationParams

import {Alert} from '@/shared-components/Alert'
import {resetNotifications} from '@ReduxActions'
import {PropsWithChildren, useCallback, useEffect} from 'react'
import logo from '../../assets/img/logo-pepsico-h.svg'
import {Header} from '../../shared-components/Header'
import {SidebarMenu} from '../../shared-components/SidebarMenu'
import {store, useAppSelector} from '../../store'
import {HeaderExtraItems} from './components/HeaderExtraItems'
import {selectDarkMode} from '@/store/slices/commonSlice'
import {useAuth} from '@/hooks/useAuth'
import {useLocation} from 'react-router-dom'

export const Template = ({children}: PropsWithChildren) => {
  const {isAuthenticated} = useAuth()

  return isAuthenticated ? <Logged>{children}</Logged> : <NotLogged />
}

const Logged = (props: PropsWithChildren) => {
  const {show, type, message} = useAppSelector(state => state.notifications)
  const isDark = useAppSelector(selectDarkMode)

  const {isOpen} = useAppSelector(state => state.nbaDrawer)

  const onCloseAlert = useCallback(() => {
    store.dispatch(resetNotifications())
  }, [])

  return (
    <>
      <Header logo={logo} title="Next Best Action Tool">
        <HeaderExtraItems />
      </Header>
      <div data-testid="content" className="relative flex h-full max-h-[calc(100vh-4rem)]">
        <SidebarMenu />
        <main
          data-testid="content-main"
          data-cy="content-main"
          className={`${isDark ? 'dark' : ''} ml-20 w-full overflow-auto bg-background pl-7 pr-6 pt-7 text-zinc-800`}
          {...props}
        />
        {!isOpen ? (
          <Alert data-cy="feedback-alert" type={type} message={message} show={show} onCloseClick={onCloseAlert} />
        ) : null}
      </div>
    </>
  )
}

const NotLogged = () => {
  const {handleLogin} = useAuth()
  const {pathname, search} = useLocation()

  useEffect(() => {
    localStorage.setItem('previousPath', pathname + search)
    handleLogin('redirect')
  }, [pathname, search, handleLogin])

  return null

  // return (
  //   <>
  //     <Header logo={logo} title={formatMessage({id: 'GENERAL.HEADER_TITLE'})}>
  //       <HeaderExtraItems />
  //     </Header>
  //     <div data-testid="content" className="relative flex h-full">
  //       <SidebarMenu />
  //       <main data-testid="content-main-guest" className="h-full w-full bg-background px-4 text-zinc-800" {...props}>
  //         <div className="flex justify-center pt-10">
  //           <PrimaryButton data-testid="btn-login" onClick={() => handleLogin('popup')}>
  //             {authState == 'login' ? formatMessage({id: 'GENERAL.SIGNING_IN'}) : formatMessage({id: 'GENERAL.LOGIN'})}
  //           </PrimaryButton>
  //         </div>
  //       </main>
  //     </div>
  //   </>
  // )
}

import {DateRangeSelector} from '@/components/Drawer/ContentParams/DateRangeSelector'
import SendPushParams from '@/components/Drawer/ContentParams/RealtimeNotificationStepConfig/SendPushParams'
import {StepConfigLoader} from '@/components/Drawer/ContentParams/StepConfigLoader/StepConfigLoader'
import DrawerNotificationButtonGroups from '@/components/Drawer/DrawNotificationButtonGroups'
import {STEP_CONFIG} from '@/config/constants'
import {
  fallsOnBankHolidayConfig,
  fallsOnBankHolidayType,
  notificationTypeConfig,
  NotificationTypeType,
} from '@/config/EventTriggerConfig'
import {useTimezone} from '@/hooks/useTimezone'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {InputText} from '@/shared-components/InputText'
import {InputTextArea} from '@/shared-components/InputTextArea'
import {TimeSelector} from '@/shared-components/TimeSelector'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {NotificationDrawerStatusEnum} from '@/types/NotificationDrawerStatusEnum'
import {Period} from '@/types/Period'
import {GetSendPushStepConfigResponse} from '@/types/StepConfigParams'
import {TimeExecutionWindowNotification} from '@/types/TimeExecution'
import {useQueryClient} from '@tanstack/react-query'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import NotificationParamCard from '../NotificationParamsFilterCard/NotificationParamCard'

interface Props {
  notificationName: string
  setNotificationName: (value: string | ((prevValue: string) => string)) => void
  notificationDescription: string
  setNotificationDescription: (value: string | ((prevValue: string) => string)) => void
  notificationType: string
  setNotificationType: (value: string | ((prevValue: string) => string)) => void
  subject: string
  setSubject: (value: string | ((prevValue: string) => string)) => void
  message: string
  setMessage: (value: string | ((prevValue: string) => string)) => void
  period: Period
  setPeriod: (value: Period | ((prevValue: Period) => Period)) => void
  timeExecutionWindow: TimeExecutionWindowNotification
  setTimeExecutionWindow: (
    value:
      | TimeExecutionWindowNotification
      | ((prevValue: TimeExecutionWindowNotification) => TimeExecutionWindowNotification)
  ) => void
  fallsOnBankHoliday: string
  setFallsOnBankHoliday: (value: string | ((prevValue: string) => string)) => void
  updateDrawStatus: (status: NotificationDrawerStatusEnum) => void
  isRemoteFetch: boolean
  closeDrawer: () => void
  saveAsDraftAndCloseDrawer: () => void
}

export default function CreateNotificationPage({
  notificationName,
  setNotificationName,
  notificationDescription,
  setNotificationDescription,
  notificationType,
  setNotificationType,
  subject,
  setSubject,
  message,
  setMessage,
  period,
  setPeriod,
  timeExecutionWindow,
  setTimeExecutionWindow,
  fallsOnBankHoliday,
  setFallsOnBankHoliday,
  updateDrawStatus,
  isRemoteFetch,
  closeDrawer,
  saveAsDraftAndCloseDrawer,
}: Props) {
  const {formatMessage} = useIntl()
  const [isAllFieldsValid, setIsAllFieldsValid] = useState<boolean>(false)
  const {tz} = useTimezone()

  const goToPreviewPage = function (): void {
    updateDrawStatus(NotificationDrawerStatusEnum.preview)
  }

  const isAdvancedParametersValid = (
    period: Period,
    timeExecutionWindow: TimeExecutionWindowNotification,
    fallsOnBankHoliday: string
  ) => {
    if (period.start === '') {
      return false
    }
    if (timeExecutionWindow.start === '' || timeExecutionWindow.end === '') {
      return false
    }
    if (fallsOnBankHoliday === '') {
      return false
    }
    return true
  }

  useEffect(() => {
    if (
      notificationName === '' ||
      notificationType === '' ||
      !isAdvancedParametersValid(period, timeExecutionWindow, fallsOnBankHoliday)
    ) {
      setIsAllFieldsValid(false)
    } else {
      setIsAllFieldsValid(true)
    }
  }, [notificationName, notificationType, period, timeExecutionWindow, fallsOnBankHoliday])

  const notificationTypeOptions: React.JSX.Element[] = notificationTypeConfig
    .filter(item => item.isEnabled())
    .map((item: NotificationTypeType) => {
      return (
        <option value={item.type} key={item.type}>
          {formatMessage({id: item.name})}
        </option>
      )
    })

  const queryClient = useQueryClient()
  const getStepConfigData = {
    sendPush: () =>
      queryClient.getQueryData([STEP_CONFIG.DATA_QUERY_KEY, NodeTypeEnum.sendPush]) as GetSendPushStepConfigResponse,
  }

  const fallsOnBankHolidayOptions: React.JSX.Element[] = fallsOnBankHolidayConfig.map(
    (item: fallsOnBankHolidayType) => {
      return (
        <option value={item.name} key={item.name}>
          {formatMessage({id: item.name})}
        </option>
      )
    }
  )

  return (
    <>
      {/* <div className="col-span-4">Event Based Setting Placeholder for new layout</div> */}
      <div
        className="col-span-8 mb-16 flex grid-cols-subgrid flex-col gap-6"
        data-testid="create-new-notification-container"
      >
        <div className="border-b pb-4" style={{borderColor: '#DFDFDF'}}>
          <div className="flex flex-col pb-6 text-text-secondary">
            <p className="text-sm font-normal">{formatMessage({id: 'NOTIFICATION_BUILDER.EDITOR_SUBHEADER'})}</p>
          </div>
          <div className="items-left flex h-fit w-full max-w-[678px] flex-col gap-6 overflow-auto px-1">
            <div className="h-42 flex w-full max-w-xs flex-col gap-1">
              <InputText
                label={formatMessage({id: 'NOTIFICATION_BUILDER.NOTIFICATION_NAME_LABEL'})}
                placeholder={formatMessage({id: 'NOTIFICATION_BUILDER.NOTIFICATION_NAME_PLACEHOLDER'})}
                onChange={e => {
                  setNotificationName(e.target.value)
                }}
                value={notificationName}
                maxLength={100}
                id="notification-name"
                required
              />
            </div>
            <div className="h-42 flex w-full max-w-xs flex-col gap-1">
              <InputTextArea
                label={formatMessage({id: 'NOTIFICATION_BUILDER.NOTIFICATION_DESCRIPTION_LABEL'})}
                placeholder={formatMessage({id: 'NOTIFICATION_BUILDER.NOTIFICATION_NAME_PLACEHOLDER'})}
                onChange={e => {
                  setNotificationDescription(e.target.value)
                }}
                value={notificationDescription}
                id="notification-description"
                required
              />
            </div>
            <div className="h-42 flex w-full flex-wrap gap-6">
              <div className="flex w-full max-w-[300px] gap-2">
                <InputSelector
                  data-testid="event-select"
                  onChange={() => {}}
                  label={formatMessage({id: 'NOTIFICATION_BUILDER.EVENT_LABEL'})}
                  id="status-select"
                  required
                />
              </div>
              <div className="flex w-full max-w-[300px] gap-2">
                <InputSelector
                  onChange={() => {}}
                  label={formatMessage({id: 'NOTIFICATION_DETAILS.PARAMETER_CONDITION_OPERATOR'})}
                  required
                />
              </div>
              <div className="flex w-full max-w-[300px] gap-2">
                <InputSelector
                  onChange={() => {}}
                  label={formatMessage({id: 'NOTIFICATION_DETAILS.PARAMETER_CONDITION_VALUE'})}
                  required
                />
              </div>
            </div>
            <div>
              <NotificationParamCard />
            </div>
            <div>
              <DateRangeSelector
                timezone={tz}
                start={period.start}
                end={period?.start ? period?.end : undefined}
                onChange={(start = '', end = '') => {
                  setPeriod({start, end})
                }}
                showInfo={false}
                isStartDateRequired={true}
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-col gap-2 text-text-secondary">
              <p className="text-sm font-bold">
                {formatMessage({id: 'NOTIFICATION_DETAILS.TIME_EXECUTION_WINDOW_TITLE'})}
              </p>
              <div
                key={'rtn-time-selector'}
                className="flex w-full items-center gap-7"
                data-testid="time-execution-window-item"
              >
                <div className="h-42 flex w-full max-w-xs flex-col gap-1">
                  <TimeSelector
                    className="flex-1"
                    label={formatMessage({id: 'NBA_BUILDER.TIME_EXECUTION_WINDOW_START'})}
                    empty={formatMessage({id: 'NBA_BUILDER.TIME_EXECUTION_WINDOW_SELECT_START'})}
                    id={`time-execution-window-start-1`}
                    value={timeExecutionWindow?.start}
                    onChange={evt => setTimeExecutionWindow({start: evt.target.value, end: timeExecutionWindow?.end})}
                    disabled={false}
                    required
                  />
                </div>

                <div className="flex w-full items-center gap-6">
                  <div className="h-42 flex w-full max-w-xs flex-col gap-1">
                    <TimeSelector
                      className="flex-1"
                      label={formatMessage({id: 'NBA_BUILDER.TIME_EXECUTION_WINDOW_END'})}
                      empty={formatMessage({id: 'NBA_BUILDER.TIME_EXECUTION_WINDOW_SELECT_END'})}
                      id={`time-execution-window-end-1`}
                      value={timeExecutionWindow?.end}
                      onChange={evt =>
                        setTimeExecutionWindow({start: timeExecutionWindow?.start ?? '', end: evt.target.value})
                      }
                      startTime={timeExecutionWindow?.start}
                      disabled={false}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 flex flex-col">
            <div className="h-42 flex w-full max-w-xs flex-col gap-3">
              <InputSelector
                data-testid="falls-on-bank-holiday-select"
                onChange={evt => {
                  setFallsOnBankHoliday(evt.target.value)
                }}
                value={fallsOnBankHoliday}
                label={formatMessage({id: 'NOTIFICATION_BUILDER.IF_FALLS_ON_BANK_HOLIDAY_LABEL'})}
                id="falls-on-bank-holiday-select"
                required
              >
                <option value="">{formatMessage({id: 'NOTIFICATION_BUILDER.BANK_HOLIDAY_DEFAULT_OPTION'})}</option>
                {fallsOnBankHolidayOptions}
              </InputSelector>
            </div>
          </div>
        </div>

        <div className="flex flex-col text-text-secondary">
          <p className="text-sm font-normal">{formatMessage({id: 'NOTIFICATION_BUILDER.DETAILS_SUBHEADER'})}</p>
        </div>
        <div className="flex flex-col">
          <div className="h-42 flex w-full max-w-xs flex-col gap-3">
            <InputSelector
              onChange={e => {
                setNotificationType(e.target.value)
              }}
              value={notificationType}
              label={formatMessage({id: 'NOTIFICATION_BUILDER.NOTIFICATION_TYPE_LABEL'})}
              id="notification-type"
              required
            >
              <option value="">{formatMessage({id: 'NOTIFICATION_BUILDER.NOTIFICATION_DEFAULT_OPTION'})}</option>
              {notificationTypeOptions}
            </InputSelector>
            {notificationType === 'SEND_PUSH' && (
              <StepConfigLoader type={NodeTypeEnum.sendPush} fetchFromRemote={isRemoteFetch}>
                <SendPushParams
                  getData={getStepConfigData.sendPush}
                  setSubject={setSubject}
                  setMessage={setMessage}
                  subject={subject}
                  message={message}
                />
              </StepConfigLoader>
            )}
          </div>
          <DrawerNotificationButtonGroups
            hasSecondaryButton={true}
            hasTertiaryButton={true}
            testIdPrimary="preview-button"
            testIdSecondary="save-as-draft-button"
            testIdTertiary="cancel-button"
            onClickPrimary={goToPreviewPage}
            onClickSecondary={saveAsDraftAndCloseDrawer}
            onClickTertiary={closeDrawer}
            primaryButtonLabel={formatMessage({
              id: 'NOTIFICATION_BUILDER.PREVIEW_BUTTON',
            })}
            secondaryButtonLabel={formatMessage({
              id: 'NOTIFICATION_BUILDER.SAVE_AS_DRAFT_BUTTON',
            })}
            tertiaryButtonLabel={formatMessage({
              id: 'NOTIFICATION_BUILDER.CANCEL_BUTTON',
            })}
            isPrimaryDisabled={!isAllFieldsValid}
            isSecondaryDisabled={!isAllFieldsValid}
          />
        </div>
      </div>
    </>
  )
}

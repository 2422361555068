import {NotificationTypeEnum} from '@/types/NotificationTypeEnum'

export type NotificationTypeType = {
  name: string
  type: NotificationTypeEnum
  isEnabled: () => boolean
}

export type fallsOnBankHolidayType = {
  name: string
}

export const notificationTypeConfig: NotificationTypeType[] = [
  {name: 'NBA_BUILDER.SEND_PUSH_WIDGET_LABEL', type: NotificationTypeEnum.sendPush, isEnabled: () => true},
  {name: 'NBA_BUILDER.SEND_SMS_WIDGET_LABEL', type: NotificationTypeEnum.sendSMS, isEnabled: () => false},
  {name: 'NBA_BUILDER.SEND_WHATSAPP_WIDGET_LABEL', type: NotificationTypeEnum.sendWhatsApp, isEnabled: () => false},
]

export const fallsOnBankHolidayConfig: fallsOnBankHolidayType[] = [
  {name: 'NOTIFICATION_BUILDER.BANK_HOLIDAY_SAME_DAY_OPTION'},
  {name: 'NOTIFICATION_BUILDER.BANK_HOLIDAY_NEXT_DAY_OPTION'},
]

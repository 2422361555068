import {useIntl} from 'react-intl'

export const HowtoSave = () => {
  const {formatMessage} = useIntl()

  return (
    <>
      <p>
        You can save the communication journey <b> anytime</b>, while it's in "DRAFT" status, and has at least one step
        added after the <i>{formatMessage({id: 'NBA_BUILDER.ENTRY_CONDITION_WIDGET_LABEL'})}</i>.
      </p>
      <p>
        <b> Attention: </b> once the journey is published, it can't be changed!
      </p>
      <ol>
        <li>
          Click on the <b> Save as Draft </b> button at the bottom right corner of the journey builder.
          <img src="/img/userGuide/save-button.png" width="132" alt="Save Journey button" />
        </li>
      </ol>
    </>
  )
}

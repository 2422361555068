import {ROUTES} from '@/config/constants'
import {useAuth} from '@/hooks/useAuth'
import {Avatar, Icon} from 'pepsico-ds'
import {PropsWithChildren, useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

type HeaderProps = PropsWithChildren & {
  logo: string
  title: string
}

export const Header = ({logo, title, ...props}: HeaderProps) => {
  const navigate = useNavigate()

  return (
    <header
      data-testid="header"
      className="min-w-screen flex h-16 items-center justify-between bg-header-dark px-6 text-white"
    >
      <div data-testid="header-logo" className="flex h-auto flex-1 cursor-pointer" onClick={() => navigate('/')}>
        <img src={logo} alt="Pepsico logo" className="w-28" />
      </div>
      <div data-testid="header-title" className="flex flex-1 items-center gap-2 text-base font-bold text-zinc-50">
        <Icon icon="grid_view" size="medium" style={{fontSize: '1rem'}} /> {title}
      </div>
      <div data-testid="header-user" className="flex w-auto items-center justify-end">
        {props.children && (
          <div data-testid="header-extra-icons" className="mr-4 flex flex-1 items-center">
            {props.children}
          </div>
        )}
        <UserAvatar />
      </div>
    </header>
  )
}

const UserAvatar = () => {
  const {claims, accessToken} = useAuth()
  const navigate = useNavigate()
  const [profileImage, setProfileImage] = useState<string | undefined>()

  const fetchProfileImage = useCallback(async () => {
    if (!accessToken) return

    try {
      const res = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers: {
          Authorization: accessToken,
        },
      })
      const blob = await res.blob()
      const url = URL.createObjectURL(blob)
      setProfileImage(url)
    } catch (error) {
      console.error('Error fetching profile image:', error)
    }
  }, [accessToken])

  useEffect(() => {
    fetchProfileImage()
  }, [fetchProfileImage])

  const {firstName, lastName} = claims

  const fullName = !!firstName && !!lastName ? `${firstName} ${lastName}` : ''

  return (
    <span data-testid="header-user-avatar">
      <Avatar
        onError={() => setProfileImage('')}
        name={fullName}
        className="h-8 w-8"
        onClick={() => navigate(ROUTES.LOGOUT)}
        imageUrl={profileImage}
      />
    </span>
  )
}

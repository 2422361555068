import DrawerNotificationButtonGroups from '@/components/Drawer/DrawNotificationButtonGroups'
import NotificationDetails, {NotificationDetailsProps} from '@/components/Drawer/NotificationDetails'
import NotificationSubject from '@/components/Drawer/NotificationSubject'
import {useIntl} from 'react-intl'

interface Props extends NotificationDetailsProps {
  closeDrawer: () => void
  saveAsDraftAndCloseDrawer: () => void
  publishNotification: () => void
}

export default function NotificationDetailsPreview({
  notificationName,
  notificationDescription,
  period,
  timeExecutionWindow,
  fallsOnBankHoliday,
  closeDrawer,
  saveAsDraftAndCloseDrawer,
  publishNotification,
}: Props): React.JSX.Element {
  const {formatMessage} = useIntl()

  return (
    <>
      <div className="col-span-4 mb-20">
        <div className="text-sm text-text-secondary" data-testid="preview-drawer">
          {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_PREVIEW_HEADER'})}
        </div>
        <NotificationSubject />
        <NotificationDetails
          notificationName={notificationName}
          notificationDescription={notificationDescription}
          period={period}
          timeExecutionWindow={timeExecutionWindow}
          fallsOnBankHoliday={fallsOnBankHoliday}
        />
      </div>
      <DrawerNotificationButtonGroups
        hasSecondaryButton={true}
        hasTertiaryButton={true}
        testIdPrimary="preview-button"
        testIdSecondary="save-as-draft-button"
        testIdTertiary="cancel-button"
        onClickPrimary={publishNotification}
        onClickSecondary={saveAsDraftAndCloseDrawer}
        onClickTertiary={closeDrawer}
        primaryButtonLabel={formatMessage({
          id: 'NOTIFICATION_BUILDER.PUBLISH_BUTTON',
        })}
        secondaryButtonLabel={formatMessage({
          id: 'NOTIFICATION_BUILDER.SAVE_AS_DRAFT_BUTTON',
        })}
        tertiaryButtonLabel={formatMessage({
          id: 'NOTIFICATION_BUILDER.CANCEL_BUTTON',
        })}
        isPrimaryDisabled={false}
        isSecondaryDisabled={false}
      />
    </>
  )
}

import NotificationDetails from '@/components/Drawer/NotificationDetails'
import NotificationSubject from '@/components/Drawer/NotificationSubject'
import {getNotificationByIdUseCase} from '@/useCases/getNotificationByIdUseCase'
import {useQuery} from '@tanstack/react-query'
import {useIntl} from 'react-intl'

interface Props {
  notificationId: string
}

export default function EventNotificationDetails({notificationId}: Props): React.JSX.Element {
  const {formatMessage} = useIntl()
  // data parsing should depend on the notificationId API response data for future updates.
  const mockData = {
    name: 'New Mock Notification',
    description: 'Mock Description',
    country: 'Turkey',
    event: 'Event details',
    duration: {start: '10/10/2023', end: '10/10/2030'},
    timeExecutionWindow: {start: '9:00am', end: '10:00pm'},
    recurrency: 'Monday, Tuesday',
    fallsOnBankHoliday: 'NOTIFICATION_BUILDER.BANK_HOLIDAY_SAME_DAY_OPTION',
  }

  const {isFetching, data, error} = useQuery({
    queryKey: ['notifications', notificationId],
    queryFn: ({signal}) => getNotificationByIdUseCase({id: notificationId}, signal),
  })

  const eventName = data?.data?.entry_conditions[0]?.trait?.name ?? ''
  const parameters = (data?.data?.entry_conditions[0]?.traitparameters ?? []).map((item, index) => {
    return {
      id: item.id_traitparameter,
      title: `Parameter ${index + 1}`,
      trait: item.trait_name,
      operator: item.operator_name,
      value: item.value,
    }
  })

  return (
    <div className="-mt-1 overflow-auto px-1" data-testid="event-notification-details-container">
      <div className="mb-4 text-lg font-bold text-text-primary">
        {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_HEADER'})}
      </div>
      {isFetching && (
        <p className="text-md my-16 items-center text-center" data-testid="notification-loading">
          {formatMessage({id: 'NOTIFICATION_DETAILS.LOADING_DETAILS_LABEL'})}
        </p>
      )}
      {!isFetching && error && (
        <p className="text-md my-16 items-center text-center" data-testid="notification-error">
          {formatMessage({id: 'NOTIFICATION_DETAILS.ERROR_DETAILS_LABEL'})}
        </p>
      )}
      {!isFetching && !error && (
        <div data-testid="notification-details">
          <NotificationDetails
            notificationName={data?.data?.name ?? ''}
            notificationDescription={data?.data?.description ?? ''}
            event={eventName}
            country={data?.data?.country_name ?? ''}
            parameters={parameters}
            period={mockData?.duration}
            timeExecutionWindow={mockData?.timeExecutionWindow}
            fallsOnBankHoliday={mockData?.fallsOnBankHoliday}
          />
          <div className="mt-4 text-text-primary">
            <div className="text-base font-bold">
              {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_DETAILS_SECTION_TITLE'})}
            </div>
            <NotificationSubject />
          </div>
        </div>
      )}
    </div>
  )
}

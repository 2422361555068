import {GetNotificationsResponse} from '@/types/Notifications'
import {getNotificationsService} from '../services/notificationsService'

export type GetNotificationUseCaseParams = {
  idCountry?: number
  pageNumber?: number
  pageSize?: number
  sortBy?: string
}

export const DEFAULT_SORT = 'name,asc'

export const DEFAULT_PAGE_SIZE = 10

export async function getNotificationsUseCase(
  params?: GetNotificationUseCaseParams,
  signal?: AbortSignal
): Promise<GetNotificationsResponse> {
  const {idCountry = 1, pageNumber = 0, pageSize = DEFAULT_PAGE_SIZE, sortBy = DEFAULT_SORT} = params || {}
  return getNotificationsService({idCountry, pageNumber, pageSize, sortBy}, signal)
}

import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {useIntl} from 'react-intl'

export const HowtoCreate = () => {
  const {formatMessage} = useIntl()

  return (
    <>
      <p>Starting at the Communication Journeys list page:</p>
      <div className="flex gap-4">
        <ol className="w-full">
          <li>
            Click on the <b> Create Journey </b> button at the top right corner of the journey builder.
            <img src="/img/userGuide/create-new-button.png" width="132" alt="Create new Journey button" />
          </li>
          <li>
            Fill in the required fields for the <b> Journey Details </b> and click on the <i> Continue </i>
            button at the bottom right of the configuration drawer.
          </li>
        </ol>

        <p className="w-1/2 border-l-4 border-yellow-300/50 bg-yellow-100/10 p-4 font-medium italic">
          To edit these details later, from the Journey Details side panel, click on the <b>Edit</b> button.
          <img src="/img/userGuide/edit-journey-details-button.png" width="132" alt="Edit Journey Details button" />
        </p>
      </div>

      <h4 className="py-4 text-lg font-medium">Journey Details options</h4>
      <table>
        <thead>
          <tr>
            <th className="w-1/4">Configuration</th>
            <th className="w-full">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Basic information</b>
            </td>
            <td>
              Users can define the <b>name</b> of the journey, and an optional <b> description </b>.
            </td>
          </tr>

          {isFeatureEnabled(FeatureToggle.journeyType) && (
            <tr data-testid="howto-journeyType">
              <td>
                <b> {formatMessage({id: 'NBA_BUILDER.NBA_TYPE_LABEL'})} </b>
              </td>
              <td>
                Users can choose between a <b> {formatMessage({id: 'NBA_BUILDER.NBA_TYPE_RECURRENT'})} </b> and
                <b> {formatMessage({id: 'NBA_BUILDER.NBA_TYPE_ONE_TIME'})} </b> journey.
                <ul>
                  <li>
                    <b> {formatMessage({id: 'NBA_BUILDER.NBA_TYPE_ONE_TIME'})} </b>:
                    <ul>
                      <li>
                        No <i> {formatMessage({id: 'NBA_BUILDER.WAIT_WIDGET_LABEL'})} </i> or
                        <i> {formatMessage({id: 'NBA_BUILDER.REQUIRES_INTERVENTION_WIDGET_LABEL'})} </i> steps allowed.
                      </li>
                      <li>
                        Only one step can be added after the
                        <i> {formatMessage({id: 'NBA_BUILDER.ENTRY_CONDITION_WIDGET_LABEL'})} </i>.
                      </li>
                      <li>Re-entry days cannot be specified.</li>
                      <li>
                        Can be converted to a {formatMessage({id: 'NBA_BUILDER.NBA_TYPE_RECURRENT'})} journey, retaining
                        the existing step.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b> {formatMessage({id: 'NBA_BUILDER.NBA_TYPE_RECURRENT'})} </b>:
                    <ul>
                      <li>
                        Multiple steps can be added after the
                        <i> {formatMessage({id: 'NBA_BUILDER.ENTRY_CONDITION_WIDGET_LABEL'})} </i>.
                      </li>
                      <li>Cannot be converted to a {formatMessage({id: 'NBA_BUILDER.NBA_TYPE_ONE_TIME'})} journey.</li>
                    </ul>
                  </li>
                </ul>
              </td>
            </tr>
          )}

          {isFeatureEnabled(FeatureToggle.scheduleDateRange) && (
            <tr data-testid="howto-scheduleDateRange">
              <td>
                <b>{formatMessage({id: 'NBA_BUILDER.DURATION'})}</b>
              </td>
              <td>
                Users can specify a <b>start date</b> for when the journey should begin. If no start date is set{' '}
                <i>(or if the date is in the past)</i>, the journey will start immediately upon publication.
                <br />
                For a <i> Recurrent </i> journey, users can specify a <b>start date</b> and an optional <b>end date</b>.
                If they choose not to set an end date, the journey will continue indefinitely.
              </td>
            </tr>
          )}

          {isFeatureEnabled(FeatureToggle.recurrence) && (
            <tr data-testid="howto-recurrence">
              <td>
                <b>{formatMessage({id: 'NBA_BUILDER.RECURRENCE_TITLE'})}</b>
              </td>
              <td>
                <b> Repeat every: </b> determines when a journey should run. For example every day, every week, every
                month, every year.
              </td>
            </tr>
          )}

          {isFeatureEnabled(FeatureToggle.timeExecutionWindow) && (
            <tr data-testid="howto-timeExecutionWindow">
              <td>
                <b>{formatMessage({id: 'NBA_BUILDER.TIME_EXECUTION_WINDOW_TITLE'})}</b>
              </td>
              <td>
                Users can define the time execution window during which notifications can be sent to customers. This is
                to prevent notifications from being sent during inappropriate hours, such as late at night.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

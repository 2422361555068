import {createNotificationService} from '@/services/notificationsService'
import {CreateNotificationResponse, EntryCondition, Step} from '@/types/Notifications'

export type CreateNotificationUseCaseParams = {
  id_country: number
  id_eventstatus: number
  name: string
  description: string
  active: boolean
  redirect_page?: string
  entry_conditions: EntryCondition[]
  steps: Step[]
}

export async function createNotificationUseCase(
  params: CreateNotificationUseCaseParams
): Promise<CreateNotificationResponse> {
  try {
    const response = await createNotificationService(params)
    return response
  } catch (error) {
    console.log('Error in createNotificationUseCase:', error)
    throw error
  }
}

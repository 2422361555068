import {FeatureToggle} from '@/config/featureToggles'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {useIntl} from 'react-intl'

export const HowtoSteps = () => {
  const {formatMessage} = useIntl()

  return (
    <>
      <div className="flex gap-4">
        <ul className="w-full">
          <li>
            To <b> add a new step </b>, just click on the plus button from the previous step connection and select the
            desired step from the configuration drawer.
            <br />
            Fill all the required fields, and click on the <b> Add Step </b> button.
          </li>
        </ul>
        <span className="w-1/2 border-l-4 border-yellow-300/50 bg-yellow-100/10 p-4 font-medium italic">
          <img src="/img/userGuide/add-new-step-button.png" width="172" alt="Add new Step button" />
        </span>
      </div>
      <div className="flex gap-4">
        <ul className="flex w-full flex-col gap-4">
          <li>
            To <b> edit a step </b> configuration, from the Journey Builder screen, click on the <b> Edit button </b> of
            the step or double-click on the step widget.
          </li>
          <li>
            To <b> delete a step </b>, click on the <b> Delete button </b> and confirm the deletion. When an
            intermediate step is deleted, the remaining steps will be auto-connected to which other.
          </li>
        </ul>
        <span className="w-1/2 border-l-4 border-yellow-300/50 bg-yellow-100/10 p-4 font-medium italic">
          <img src="/img/userGuide/edit-delete-step-buttons.png" width="97" alt="Edit step configuration button" />
        </span>
      </div>

      <p>Each step has its own function and configuration.</p>

      <table>
        <thead>
          <tr>
            <th className="w-1/4">Step Name</th>
            <th className="w-1/2">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>{formatMessage({id: 'NBA_BUILDER.SEND_SMS_WIDGET_LABEL'})}</b>
            </td>
            <td>Add this step to indicate when a SMS message should be sent to the audience.</td>
          </tr>
          <tr>
            <td>
              <b>{formatMessage({id: 'NBA_BUILDER.SEND_PUSH_WIDGET_LABEL'})}</b>
            </td>
            <td>Add this step to indicate when a Push notification should be sent to the audience.</td>
          </tr>
          <tr>
            <td>
              <b>{formatMessage({id: 'NBA_BUILDER.SEND_WHATSAPP_WIDGET_LABEL'})}</b>
            </td>
            <td>Add this step to indicate when a WhatsApp message should be sent to the audience.</td>
          </tr>
          <tr>
            <td>
              <b>{formatMessage({id: 'NBA_BUILDER.WAIT_WIDGET_LABEL'})}</b>
            </td>
            <td>
              <p>Add this step to indicate when the journey flow should wait before proceeding to the next step.</p>
              {isFeatureEnabled(FeatureToggle.journeyType) && (
                <p>
                  <i>
                    <b> Obs: </b> This step is only available when the type of journey is
                    <b> {formatMessage({id: 'NBA_BUILDER.NBA_TYPE_RECURRENT'})} </b>.
                  </i>
                </p>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>{formatMessage({id: 'NBA_BUILDER.REQUIRES_INTERVENTION_WIDGET_LABEL'})}</b>
            </td>
            <td>
              <p>
                Add this step when an intervention action by the sales rep is required. For example, if there are many
                stores that haven't logged in or have been inactive for a long time in the B2B app, users can download a
                report to contact the salesperson. They can then ask the salesperson to talk to the stores to find out
                why they're not using the app and encourage them to do so. This step can only be added at the end of the
                process, and no more steps can be added after it.
              </p>
              {isFeatureEnabled(FeatureToggle.journeyType) && (
                <p>
                  <i>
                    <b> Obs: </b> This step is only available when the type of journey is
                    <b> {formatMessage({id: 'NBA_BUILDER.NBA_TYPE_RECURRENT'})} </b>.
                  </i>
                </p>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

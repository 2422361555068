import {timezones} from '@/config/constants'
import {useAppSelector} from '@/store'

export const useTimezone = () => {
  const {country} = useAppSelector(state => state.common)

  const tz = timezones[country.code as keyof typeof timezones]
  if (!tz) throw new Error(`Timezone not found for country ${country.code}`)

  return {
    country,
    tz,
  }
}

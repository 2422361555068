import {Period} from '@/types/Period'
import {TimeExecutionWindowNotification} from '@/types/TimeExecution'
import {useIntl} from 'react-intl'

export type ParameterItem = {
  id: number
  title: string
  trait: string
  operator: string
  value: string
}

export interface NotificationDetailsProps {
  notificationName: string
  notificationDescription: string
  parameters?: Array<ParameterItem>
  event?: string
  country?: string
  period?: Period
  timeExecutionWindow?: TimeExecutionWindowNotification
  fallsOnBankHoliday?: string
}

export default function NotificationDetails({
  notificationName,
  notificationDescription,
  parameters = [],
  event = '',
  country = '',
  period,
  timeExecutionWindow,
  fallsOnBankHoliday,
}: NotificationDetailsProps) {
  const {formatMessage} = useIntl()

  const parameterDetails = parameters.map(item => {
    return (
      <div key={item.id} className="mt-8 rounded-lg border border-blue-500 p-4">
        <div className="mb-2 text-lg font-bold">{item.title}</div>
        <div className="border-t border-border-gray text-xs">
          <div className="mb-1 mt-3 font-bold">
            {formatMessage({id: 'NOTIFICATION_DETAILS.PARAMETER_CONDITION_TRAIT'})}
          </div>
          <div className="mb-3 mt-1">{item.trait}</div>
        </div>
        <div className="border-t border-border-gray text-xs">
          <div className="mb-1 mt-3 font-bold">
            {formatMessage({id: 'NOTIFICATION_DETAILS.PARAMETER_CONDITION_OPERATOR'})}
          </div>
          <div className="mb-3 mt-1">{item.operator}</div>
        </div>
        <div className="border-t border-border-gray text-xs">
          <div className="mb-1 mt-3 font-bold">
            {formatMessage({id: 'NOTIFICATION_DETAILS.PARAMETER_CONDITION_VALUE'})}
          </div>
          <div className="mb-3 mt-1">{item.value}</div>
        </div>
      </div>
    )
  })

  return (
    <div>
      <div className="border-b border-border-gray">
        <div className="pt-3 text-xs font-bold text-text-secondary">
          {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_NAME'})}
        </div>
        <div className="pb-3 pt-1 text-xs text-text-primary">{notificationName}</div>
      </div>
      <div className="border-b border-border-gray">
        <div className="pt-3 text-xs font-bold text-text-secondary">
          {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_DESCRIPTION'})}
        </div>
        <div className="pb-3 pt-1 text-xs text-text-primary">{notificationDescription}</div>
      </div>
      <div className="border-b border-border-gray">
        <div className="pt-3 text-xs font-bold text-text-secondary">
          {formatMessage({id: 'NOTIFICATION_DETAILS.COUNTRY'})}
        </div>
        <div className="pb-3 pt-1 text-xs text-text-primary">{country}</div>
      </div>
      <div className="border-b border-border-gray">
        <div className="pt-3 text-xs font-bold text-text-secondary">
          {formatMessage({id: 'NOTIFICATION_DETAILS.EVENT'})}
        </div>
        <div className="pb-3 pt-1 text-xs text-text-primary">{event}</div>
      </div>
      <div>{parameterDetails}</div>
      <div className="mt-4">
        <div className="border-b border-border-gray text-xs">
          <div className="pb-1 pt-3 font-bold text-text-secondary">
            {formatMessage({id: 'NOTIFICATION_DETAILS.DURATION'})}
          </div>
          <div className="pb-3 pt-1">{formatMessage({id: 'NOTIFICATION_DETAILS.DURATION_VALUE'}, period)}</div>
        </div>
        <div className="border-b border-border-gray text-xs">
          <div className="pb-1 pt-3 font-bold text-text-secondary">
            {formatMessage({id: 'NOTIFICATION_DETAILS.TIME_EXECUTION_WINDOW_TITLE'})}
          </div>
          <div className="pb-3 pt-1">
            {formatMessage({id: 'NOTIFICATION_DETAILS.TIME_EXECUTION_WINDOW_VALUE'}, timeExecutionWindow)}
          </div>
        </div>
        {/* <div className="border-b border-border-gray text-xs">
          <div className="pb-1 pt-3 font-bold text-text-secondary">
            {formatMessage({id: 'NOTIFICATION_DETAILS.RECURRENCE_TITLE'})}
          </div>
          <div className="pb-3 pt-1">{mockData.recurrency}</div>
        </div> */}
        <div className="border-b border-border-gray text-xs">
          <div className="pb-1 pt-3 font-bold text-text-secondary">
            {formatMessage({id: 'NOTIFICATION_DETAILS.SEND_IT_ON_TITLE'})}
          </div>
          <div className="pb-3 pt-1">{formatMessage({id: fallsOnBankHoliday})}</div>
        </div>
      </div>
    </div>
  )
}

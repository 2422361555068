import {Icon} from 'pepsico-ds'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import Modal from '../../../shared-components/Modal/Modal'
import {UserGuideContent} from './UserGuideContent/UserGuideContent'
import ThemeToggle from '@/components/ThemeToggle'
import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'

export const HeaderExtraItems = () => {
  return (
    <>
      {isFeatureEnabled(FeatureToggle.isThemeEnabled) && (
        <ThemeToggle className="flex w-full gap-2 rounded-lg px-4 py-2 outline-none hover:bg-button-icon-hover/5" />
      )}
      <UserGuide />
    </>
  )
}

const UserGuide = () => {
  const {formatMessage} = useIntl()

  const [isOpen, setIsOpen] = useState(false)
  if (!isFeatureEnabled(FeatureToggle.userGuide)) return

  return (
    <>
      <button
        className="flex w-full gap-2 rounded-lg px-4 py-2 outline-none hover:bg-button-icon-hover/5"
        title={formatMessage({id: 'HEADER.USER_GUIDE_LINK'})}
        data-testid="user-guide-button"
        data-cy="user-guide-button"
        onClick={() => setIsOpen(true)}
      >
        <Icon icon="info" size="medium" className="text-white" data-testid="information-icon" />
      </button>

      <Modal
        isOpen={isOpen}
        title={formatMessage({id: 'HEADER.USER_GUIDE_LINK'})}
        content={<UserGuideContent />}
        secondaryButtonText={formatMessage({id: 'GENERAL.CLOSE'})}
        onClickSecondaryButton={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
        classNameWrapper="h-[calc(100vh-18rem)] w-[calc(100vw-10rem)] max-w-[50rem] mb-4"
      />
    </>
  )
}

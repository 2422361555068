import {ROUTES} from '@/config/constants'
import {UserRoleContext, UserRoleProvider} from '@/helpers/UserRole/UserRoleProvider'
import {Template} from '@/pages/Template'
import {useContext, useEffect} from 'react'
import {Outlet, useNavigate, useLocation} from 'react-router-dom'
import {useAuth} from '@/hooks/useAuth'

export const ProtectedRoutes = () => {
  return (
    <UserRoleProvider>
      <Template>
        <ProtectedRoutesContent />
      </Template>
    </UserRoleProvider>
  )
}

const ProtectedRoutesContent = () => {
  const {isAuthenticated} = useAuth()
  const {isAuthorized, isReady: isUserRoleReady, error: userRoleError} = useContext(UserRoleContext)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!isAuthenticated) {
      // signInWithRedirect({originalUri: ROUTES.HOME})
      navigate(ROUTES.LOGIN)
      return
    }
    if (userRoleError) {
      navigate(ROUTES.ERROR_500)
      return
    }
    if (isUserRoleReady && !isAuthorized) {
      navigate(ROUTES.ERROR_403)
    }
  }, [isAuthenticated, isAuthorized, isUserRoleReady, navigate, userRoleError, location])

  useEffect(() => {
    if (isAuthenticated) {
      const preLoginPath = localStorage.getItem('preLoginPath')
      if (preLoginPath) {
        localStorage.removeItem('preLoginPath')
        navigate(preLoginPath)
      }
    }
  }, [isAuthenticated, navigate])

  const canRenderRoute = isAuthenticated && isAuthorized

  return canRenderRoute ? <Outlet /> : null
}
